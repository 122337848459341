import React from 'react';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import i18n from 'services/i18n';
import Routes from './routes';
import { store } from './store';

import GlobalStyle from './styles/globalStyle';
import theme from './styles/theme';

import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Routes />
          <GlobalStyle />
          <ToastContainer position="top-center" hideProgressBar autoClose={5000} />
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
