import Layout from 'components/LayoutUser';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Route } from 'react-router-dom';

NonRoute.propTypes = {
  component: PropTypes.any,
};

function NonRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest}>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </Layout>
    </Route>
  );
}

export default NonRoute;
