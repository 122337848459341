import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getListFilesApi } from 'services/apis/admin/asset';

export const getListFiles = createAsyncThunk(
  'files/GET_LIST',
  async ({ wsname, params }, { rejectWithValue }) => {
    try {
      const response = await getListFilesApi(wsname, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  files: [],
  paginate: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const FileSlice = createSlice({
  name: 'file',
  initialState,

  extraReducers: {
    [getListFiles.pending]: (state) => {
      state.loading = true;
    },
    [getListFiles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getListFiles.fulfilled]: (state, action) => {
      state.files = action?.payload;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});
const { reducer: fileReduce } = FileSlice;
export default fileReduce;
