import layout from 'components/Layout/locales/vi';
import layoutUser from 'components/LayoutUser/locales/vi';
import groupRadioButton from 'components/Form/GroupRadioButton/locales/vi';
import tablePage from 'components/commons/Table/locales/vi';
import modalConfirm from 'components/commons/ModalConfirm/locales/vi';
import modal from 'components/commons/Modal/locales/vi';
import qrCode from 'containers/OtherPage/QRCodePage/locales/vi';
import messageStatus from './message/vi';
import validation from './validation/vi';

export default {
  layout,
  layoutUser,
  tablePage,
  modalConfirm,
  validation,
  groupRadioButton,
  modal,
  messageStatus,
  qrCode,
};
