import { configureStore } from '@reduxjs/toolkit';
import workspaceReduce from './admin/workspaceSlice';
import fileReduce from './admin/assetSlice';

export const store = configureStore({
  reducer: {
    workspaceReduce,
    fileReduce,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
