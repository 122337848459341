import request from 'services/request';

export const getListWorkspaceApi = async (params) =>
  request({
    url: '/n/workspace/',
    method: 'GET',
    params,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getWorkspaceDetailApi = async (id) =>
  request({
    url: `/n/workspace/${id}/`,
    method: 'GET',
  });

export const createWorkspaceApi = async (data) =>
  request({
    url: '/n/workspace/',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const editWorkspaceApi = async (id, data, wsname) =>
  request({
    url: `/n/workspace/${id}/`,
    method: 'PUT',
    data,
    wsname,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const deleteWorkspaceApi = async (id) =>
  request({
    url: `/n/workspace/${id}/`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
