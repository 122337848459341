import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getListWorkspaceApi, getWorkspaceDetailApi } from 'services/apis/admin/workspace';

export const getListWorkspace = createAsyncThunk(
  'workspace/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListWorkspaceApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getWorkspaceDetail = createAsyncThunk(
  'workspace/GET_DETAIL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getWorkspaceDetailApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  workspaceList: [],
  workspaceDetail: {},
  loading: false,
  error: {},
};

const WorkspaceSlice = createSlice({
  name: 'workspace',
  initialState,

  extraReducers: {
    [getListWorkspace.pending]: (state) => {
      state.loading = true;
    },
    [getListWorkspace.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getListWorkspace.fulfilled]: (state, action) => {
      state.workspaceList = action?.payload;
      state.loading = false;
      state.error = {};
    },
    [getWorkspaceDetail.pending]: (state) => {
      state.loading = true;
    },
    [getWorkspaceDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getWorkspaceDetail.fulfilled]: (state, action) => {
      state.workspaceDetail = action?.payload;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: workspaceReduce } = WorkspaceSlice;
export default workspaceReduce;
