const colors = {
  blueOxford: '#373B53',
  gray1: '#333333',
  bgDrakGray: '#E5E5E5',
  bgGray1: '#f8f8fb',
  bgGray2: '#FAFAFA',
  gray3: '#999999',
  bgGray3: '#F0F0F0',
  grayline: '#EBECF4',
  blue: '#00B4D8',
  redError: '#BC0000',
  white: '#fff',
  primaryButtonColor: '#AB3E18',
  grayButtonColor: '#868A96',
  activeColor: '#F6C92B',
  borderColor: '#dce0ea',
  dark: '#303841',
  linkColor: '#868a96',
  disableText: '#868A96',
  grayTextButton: '#626262',
  backgroundAvatar: '#1480e1',
  bgMainColorbutton: '#F9F4F4',
  bgButton1: '#B76E18',
  grayTab: '#929292',
  grayBgTab: '#EEEFF4',
  black: '#000',
  grayBtn: '#C9C9C9',
  bgItem: '#F8F8F8',
  colorInfoGray: '#929292',
  bgBtnGray: '#F4F7F9' /* Color for site */,
  mainColor: '#F7CF58',
  secondaryColor: '#DCE0EA',
  textColor: '#000000',
  gray: '#2EB718',
  yellow: '#E98E07',
  bgGray: '#666F77',
  violet: '#2132CD',
  red: '#E11F1F',
  green: '#2EB718',
  greenLight: '#7DB507',
  greenDark: '#1E880C',
  scarlet: '#9C1111',
};

const fontSize = {
  xs: '12px',
  sm: '14px',
  base: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
};

const zIndex = {
  base: 1,
  dropdown: 10,
  backdrop: 50,
  modal: 100,
};

const boxShadow = {
  box: '0px 4px 4px rgba(48, 48, 48, 0.25)',
};

const borderRadius = {
  base: '4px',
  md: '8px',
  lg: '25.5px',
  xl: '28px',
};

const padding = {
  pdHeader: '8px 16px',
  pdSideBar: '20px',
};

export default {
  colors,
  fontSize,
  fontWeight,
  zIndex,
  boxShadow,
  borderRadius,
  padding,
};
