import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import ScrollToTop from 'hooks/ScrollToTop';
import NonRoute from '../hocs/NonRoute';

import { ROUTES } from './constants';

const QRCodePage = React.lazy(() => import('../containers/OtherPage/QRCodePage'));
const VideoQRCodePage = React.lazy(() => import('../containers/OtherPage/VideoQRCodePage'));

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <NonRoute exact path={ROUTES.QRCODE_PAGE} component={QRCodePage} />
        <NonRoute exact path={ROUTES.VIDEO_QRCODE_PAGE} component={VideoQRCodePage} />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
