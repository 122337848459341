export default {
  workspace: 'Workspace',
  listWorkspaces: 'Danh sách Workspace',
  createWorkspace: 'Tạo Workspace',
  student: 'Học sinh',
  listStudents: 'Danh sách học sinh',
  createStudent: 'Tạo học sinh',
  classes: 'Lớp học',
  listClasses: 'Danh sách lớp học',
  createClass: 'Tạo lớp học',
  dashboard: 'Dashboard',
  settings: 'Cài đặt',
  archive: 'Archive',
  books: 'Sách',
  listBooks: 'Danh sách sách',
  createBook: 'Tạo sách mới',
  files: 'File',
  listFiles: 'Danh sách File',
  createFile: 'Tạo File mới',
  permissions: 'Phân quyền',
  activities: 'Hoạt động',
  logOut: 'Đăng xuất',
  changeWorkspace: 'Đổi Workspace',
  confirmLogout: 'Xác nhận đăng xuất',
  sureComfirm: 'Bạn có chắc chắn muốn đăng xuất không?',
  profile: 'Profile',
};
