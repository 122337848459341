import request from 'services/request';

export const getListFilesApi = async (wsname, params) =>
  request({
    url: '/t/assets/',
    method: 'GET',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
    wsname,
    params,
  });
export const createAssetsApi = async (wsname, data) =>
  request({
    url: '/t/assets/',
    method: 'POST',
    data,
    wsname,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const deleteFileApi = async (id, wsname) =>
  request({
    url: `/t/assets/${id}/`,
    method: 'DELETE',
    wsname,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const editFileApi = async (id, data, wsname) =>
  request({
    url: `/t/assets/${id}/`,
    method: 'PUT',
    data,
    wsname,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const inviteAssetCodeApi = async (wsname, id) =>
  request({
    url: `/t/assets/${id}/invitation-code/`,
    method: 'GET',
    wsname,
  });

export const checkInvitationCode = async (data) =>
  request({
    url: `/n/assets/check-invitation-code/`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
