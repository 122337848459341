import { createGlobalStyle, css } from 'styled-components';

import media from 'styles/breakpoints';
import utility from './utility';

const reset = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

const base = css`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Open Sans', 'Poppins', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: #333333;
  }
  .robotoSlab {
    font-family: 'Roboto Slab', sans-serif;
  }
  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  a {
    text-decoration: none;
    transition: 0.4s;

    &:hover {
      opacity: 0.8;
    }
  }

  button,
  input {
    outline: none;
    border: 0;

    &:focus {
      outline: none;
    }
  }

  iframe {
    border: 0;
    width: 100%;
  }

  ::selection {
    color: #fff;
    background-color: #d72323;
  }
`;

const scrollApp = css`
  /* total width */
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border-radius: 16px;
    z-index: 1000;
    /*     border:5px solid #a0a0a5 */
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    /*     border:1px solid #a0a0a5 */
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
`;
const mobile = css`
  ${media.tablet`
    .dropMenu {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .listMenu {
      position: relative;
      top: 14px;
      width: 100%;
      height: calc(100vh - 40px);
      background: rgba(1, 76, 132, 0.95);
      .ant-dropdown-menu {
        background: transparent;
      }
      hr {
        margin: 0;
        color: #fff;
      }
      .ant-dropdown-menu-item a {
        color: #fff;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .ant-dropdown-menu-title-content {
        width: 100%;
        display: contents;
        span {
          text-transform: uppercase;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          font-family: 'Open Sans';
        }
      }
      .ant-dropdown-menu .ant-dropdown-menu-item,
      .ant-dropdown-menu .ant-dropdown-menu-submenu {
        &:last-child {
          display: flex;
          justify-content: center;
          height: 70px;
          align-items: center;
          border: none;
        }
      }
      .contact {
        border: none;
      }
    }
  }
  .ant-dropdown-hidden,
  .ant-dropdown-menu-hidden,
  .ant-dropdown-menu-submenu-hidden {
    display: none !important;
  }
  .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu .ant-dropdown-menu-submenu {
    border-bottom: 1px solid #80a6c2;
    margin: 0 16px !important;
    padding: 0;
    height: 56px;
  }
  .ant-dropdown-menu .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu .ant-dropdown-menu-submenu:hover,
  .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected,
  .ant-dropdown-menu .ant-dropdown-menu-submenu.ant-dropdown-menu-item-selected {
    background: transparent;
  }
  `}
`;
const GlobalStyle = createGlobalStyle`
  ${reset}
  ${base}
  ${utility}
  ${scrollApp}
  ${mobile}
`;

export default GlobalStyle;
